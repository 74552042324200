import Vue from 'vue'
import * as moment from 'moment/moment'
import * as rules from 'vee-validate/dist/rules'
import { extend, localize } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import enValidation from '@/validation/en.js'
import CryptoJS from 'crypto-js'
import { eventBus } from '@/main'
import constantData from './constant/constantData.json'

const imageURL = process.env.VUE_APP_API_IMAGE_PATH

// Set coustom validation messages.
localize('en', enValidation)

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

extend('price', value => {
  const strongRegex = new RegExp(/^\d{0,8}(\.\d{2})?$/)
  return strongRegex.test(value)
})

extend(
  'checkTime',
  (value, [otherValue]) =>
    //
    value >= otherValue,
)

extend('required_rule_for_editor', {
  validate: value => {
    const str = value.replace(/<p>\s*<\/p>|<p><br><\/p>|<br>/g, '')
    return str.length > 0
  },
  message: field => `${field} is required`,
})

// image validation for editor
extend('validateNoImage', {
  validate: value => {
    // Check if the content contains any image tag
    const containsImage = /<img[^>]+src="[^"]+"[^>]*>/i.test(value)
    return !containsImage
  },
  message: 'Image is not allowed',
})

const myMixin = Vue.mixin({
  directives: {
    Ripple,
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(value, 'DD-MM-YYYY').format('DD MMMM YYYY')
      }
    },
    formatDateSummery(value) {
      if (value) {
        return moment(value, 'YYYY-MM-DD').format('DD MMMM YYYY')
      }
    },
    formatTime(value) {
      if (value) {
        return moment(new Date(`${new Date().toDateString()} ${value}`)).format(
          'LT',
        )
      }
    },
    formatDateDDMMYYYY(value) {
      if (value) {
        return moment(value, 'YYYY-MM-DD').format('DD-MM-YYYY')
      }
    },
    title50(value) {
      if (value.length > 50) {
        return `${value.toUpperCase().substring(0, 50)}...`
      }
      return value
    },
    description200(value) {
      if (value.length > 200) {
        return `${value.substring(0, 200)}...`
      }
      return value
    },
    capitalize(value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    secondsInMinutes(seconds) {
      return moment(new Date())
        .startOf('day')
        .seconds(seconds)
        .format('HH:mm:ss')
    },
    hoursInTimeFormat(value) {
      const dur = moment.duration(value, 'hours')
      const hours = Math.floor(dur.asHours())
      const mins = Math.floor(dur.asMinutes()) - hours * 60

      const result = `${hours}hr ${mins}m`
      return result
    },
    hoursAndMin(value) {
      const timeString = value
      // Split the time string by the colon (:) separator
      const timeParts = timeString.split(':')
      // Extract the hour and minute parts
      const hour = timeParts[0]
      const minute = timeParts[1]
      // Concatenate the hour and minute parts
      const convertedTime = `${hour}:${minute}`
      return convertedTime
    },
    hoursInTimeFormatForSummary(value) {
      const dur = moment.duration(value, 'hours')
      const hours = Math.floor(dur.asHours())
      const mins = Math.floor(dur.asMinutes()) - hours * 60
      if (hours > 99) {
        var result = `${hours}` + ':' + `${`0${mins}`.slice(-2)} `
      } else {
        var result = `  ${`0${hours}`.slice(-2)}` + ':' + `${`0${mins}`.slice(-2)}`
      }
      return result
    },
    dayWeekformat(value) {
      if (value) {
        // Mon, Mar 7, 2022 0:46:26
        return moment(value).format('ddd, MMM DD, YYYY')
      }
    },
    timeDayFormat(value) {
      if (value) {
        return moment(value).format('MMM DD, YYYY')
      }
    },
    dateWithTimeFormat(value) {
      if (value) {
        return moment(value).format('MMM DD, YYYY [at] h:mm a')
      }
    },
    timeFormat(value) {
      if (value) {
        const date = moment(new Date()).format('DD-MM-YYYY')
        return moment(`${date} ${value}`, 'DD-MM-YYYY h:m:i').format('h:mm a')
      }
    },
    fullTimeFormat(value) {
      if (value) {
        const date = moment(new Date()).format('YYYY-MM-DD')
        return moment(`${date} ${value}`).format('h:mm a') // todo hh:mm format remove (hh:mm:ss)
      }
    },
    getDayOnly(value) {
      if (value) {
        return moment(value).format('DD')
      }
    },
    getDayNameOnly(value) {
      if (value) {
        return moment(value).format('ddd')
      }
    },
    getMonthOnly(value) {
      if (value) {
        return moment(value).format('MMM')
      }
    },
    convertInDays(value) {
      if (value) {
        const start = moment()
        const end = moment(value)
        //
        //
        const duration = end.diff(start, 'days')
        if (duration >= 0) {
          return duration > 1
            ? `${duration} Days Remaining`
            : `${duration} Day Remaining`
        }
        return duration > 1
          ? `${Math.abs(duration)} Days Sprint Moved`
          : `${Math.abs(duration)} Day Sprint Moved`
      }
    },
    // March 2023 format
    monthYearFormat(value) {
      if (value) {
        // Mon, Mar 7, 2022 0:46:26
        return moment(value).format('MMMM YYYY')
      }
    },
    DateDDMMYYYYFormat(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    attachmentURL(value) {
      if (value) {
        return `${imageURL}/${value}`
      }
    },
    avatarText(value) {
      if (!value) return ''
      const nameArray = value.split(' ')
      return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
        .length > 2
        ? nameArray
          .map(word => word.charAt(0).toUpperCase())
          .join('')
          .substring(0, 2)
        : nameArray.map(word => word.charAt(0).toUpperCase()).join('')
    },
    convertMinutes(num) {
      const hours = num / 60
      const rhours = Math.floor(hours)
      const minutes = (hours - rhours) * 60
      const rminutes = Math.round(minutes);
      `0${num}`.slice(-2)
      if (num < 6000) {
        return `${`0${rhours}`.slice(-2)}:${`0${rminutes}`.slice(-2)}`
      }
      return `${rhours}:${`0${rminutes}`.slice(-2)}`
    },
    dateFromNow(value) {
      return moment(value).fromNow()
      // date.calendar().split(' ')[0]
    },
    dateCalender(value) {
      const date = moment(value).calendar().split(' ')[0]
      if (date == 'Today' || date == 'Yesterday') {
        return date
      }
      return moment(value).fromNow()
    },

    secondsToTime(secs) {
      let minutes = Math.floor(secs / 60)
      secs %= 60
      const hours = Math.floor(minutes / 60)
      minutes %= 60
      if (hours < 99) {
        return `${`0${hours}`.slice(-2)}:${`0${minutes}`.slice(-2)}` // :${("0" + secs).slice(-2) todo HH:mm
      }
      return `${hours}:${`0${minutes}`.slice(-2)}` // :${("0" + secs).slice(-2)
    },

    /**  MORE THEN WEEK THEN SHOW DATENOW FORMAT OTHER WISE SHOW DD-MM-YYYY  HH:mm */
    DateFormatFormnowOrLocal(value) {
      if (value) {
        const currentDate = moment().subtract(7, 'd').format('YYYY-MM-DD')
        const planDate = moment(value).format('YYYY-MM-DD')
        if (planDate > currentDate) {
          return moment(value).fromNow()
        }
        return moment(value).local().format('DD-MM-YYYY')
      }
    },
    formatTimeHHMM(data) {
      return data ? moment(data, 'HH:mm:ss').format('HH:mm') : null
    },

    DDMMYYYYDDDD(value) {
      if (value) {
        return moment(value).format('DD-MM-YYYY ', ' DDDD')
      }
      return null
    },
  },
  data() {
    return {
      VUE_APP_SECRET_KEY: constantData.VUE_APP_SECRET_KEY,
      projectId: null,
      currentLayout:
        this.$route && this.$route.query && this.$route.query.layout
          ? this.$route.query.layout
          : 'vertical',
      pageName:
        this.$route && this.$route.query && this.$route.query.pageName
          ? this.$route.query.pageName
          : null,
      approvalStatus: [
        { name: 'Approved', value: 'approved' },
        { name: 'Pending', value: 'pending' },
        { name: 'Rejected', value: 'rejected' },
      ],
      roles: [
        { code: 'EMP', name: 'Employee' },
        { code: 'SA', name: 'Super Admin' },
      ],
      allTasksStatus: [
        { title: 'Backlog', status: 'backlog', color: '#FB5362' },
        { title: 'To Do', status: 'to_do', color: '#1870F0' },
        { title: 'In Progress', status: 'in_progress', color: '#F67652' },
        { title: 'In Testing', status: 'in_testing', color: '#795CEC' },
        { title: 'Done', status: 'done', color: '#23BC74' },
      ],
      workTypeList: [
        { name: 'Non Project Work', value: 'non_productive' },
        { name: 'Project Work', value: 'productive' },
      ],
      projectTypeList: [
        { label: 'Dedicated', value: 'dedicated', color: '#23BC74' },
        { label: 'Fix cost', value: 'fix_cost', color: '#F96E6F' },
        { label: 'Fix timeline', value: 'fix_timeline', color: '#F9A353' },
        { label: 'Hourly', value: 'hourly', color: '#478BFF' },
      ],
      projectStatusList: [
        { label: 'Completed', value: 'completed' },
        { label: 'In Planning', value: 'in_planning' },
        { label: 'In Progress', value: 'in_progress' },
        { label: 'In Support', value: 'in_support' },
        { label: 'On Hold', value: 'on_hold' },
      ],
      defectSeverityOptions: [
        { value: 'critical', name: 'Critical', label: 'Critical' },
        { value: 'high', name: 'High', label: 'High' },
        { value: 'medium', name: 'Medium', label: 'Medium' },
        { value: 'low', name: 'Low', label: 'Low' },
      ],
      billable_list: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ],
      allPriorities: [
        { value: 'high', name: 'High' },
        { value: 'med', name: 'Medium' },
        { value: 'low', name: 'Low' },
      ],
      clientSatisfaction: [
        { value: 1, name: 'Bad' },
        { value: 2, name: 'Ok' },
        { value: 3, name: 'Good' },
        { value: 4, name: 'Great' },
        { value: 5, name: 'Excellent' },
      ],
      projectHealthRating: [
        { value: 1, name: 'Bad' },
        { value: 2, name: 'Normal' },
        { value: 3, name: 'Good' },
      ],
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.app.userInfo
        ? this.$store.state.app.userInfo
        : null
    },
    navMenuItems() {
      const navMenuItems = []

      // admin menu items
      if (
        this.$store.state.app.userInfo
        && this.$store.state.app.userInfo.role == 'SA'
      ) {
        navMenuItems.push(
          {
            title: 'Dashboards',
            route: 'dashboard',
            icon: 'HomeIcon',
            children: [
              {
                title: 'General',
                route: 'dashboard',
              },
              {
                title: 'HR',
                route: 'hrDashBoard',
              },
              {
                title: 'QAssure',
                route: 'qassureDashBoard',
              },
            ],
          },

          {
            title: 'Clock In/Out',
            route: 'clockInOut',
            icon: 'ClockIcon',
          },
          {
            title: 'Task-Timesheet',
            route: 'timeSheet',
            icon: 'CalendarIcon',
            children: [
              {
                title: 'Task Time Log',
                route: 'timeSheetView',
              },
            ],
          },

          {
            title: 'Clock-Timesheet',
            route: 'hrTimeSheet',
            icon: 'CalendarIcon',
          },
          {
            title: 'Projects',
            route: 'projects',
            icon: 'FileIcon',
            children: [
              {
                title: 'Productive',
                route: 'projects',
              },
              {
                title: 'Non-Productive',
                route: 'nonProjects',
              },
            ],
          },
          {
            title: 'Board',
            route: 'board',
            icon: 'FileTextIcon',
          },
          {
            title: 'Reports',
            route: 'reports',
            icon: 'FileTextIcon',
            children: [
              {
                title: 'All Reports',
                route: 'reports',
              },
              {
                title: 'Scheduled report',
                route: 'scheduledReport',
              },
            ],
          },
          {
            title: 'My Team',
            route: 'myTeam',
            icon: 'UsersIcon',
          },
          {
            title: 'Resource Planning',
            route: 'resourcePlanning',
            icon: 'UsersIcon',
            children: [
              {
                title: 'Planned',
                route: 'resourcePlanning',
              },
              {
                title: 'Actual',
                route: 'resourceActualPlanning',
              },
            ],
          },
          {
            title: 'Help',
            route: 'userManual',
            icon: 'HelpCircleIcon',
            children: [
              {
                title: 'User Manual',
                route: 'userManual',
              },
              {
                title: 'Upload Media',
                route: 'mediaUpload',
              },
            ],
          },
          {
            title: 'Feedback',
            route: 'feedBack',
            icon: 'MessageCircleIcon',
          },

          {
            title: 'Master Settings',
            route: 'masterSettings',
            icon: 'SettingsIcon',
            children: [
              {
                title: 'Client',
                route: 'clients',
              },
              {
                title: 'Group',
                route: 'groupTable',
              },
              {
                title: 'Project Scope',
                route: 'projectScope',
              },
              {
                title: 'Primary skill',
                route: 'primarySkill',
              },
              {
                title: 'Tag',
                route: 'tag',
              },
              {
                title: 'Employees',
                route: 'user',
              },
            ],
          },
        )
      }
      // HR menu items
      if (
        this.$store.state.app.userInfo
        && this.$store.state.app.userInfo.role == 'HR'
      ) {
        navMenuItems.push({
          title: 'Dashboards',
          route: 'dashboard',
          icon: 'HomeIcon',
        })
        navMenuItems.push({
          title: 'Clock In/Out',
          route: 'clockInOut',
          icon: 'ClockIcon',
        })
        navMenuItems.push({
          title: 'Time Sheet',
          route: 'hrTimeSheet',
          icon: 'CalendarIcon',
        })
        navMenuItems.push({
          title: 'Master Settings',
          route: 'masterSettings',
          icon: 'SettingsIcon',
          children: [
            {
              title: 'Primary skill',
              route: 'primarySkill',
            },

            {
              title: 'Employees',
              route: 'user',
            },
          ],
        })
        navMenuItems.push({
          title: 'Help',
          route: 'userManual',
          icon: 'HelpCircleIcon',
          children: [
            {
              title: 'User Manual',
              route: 'userManual',
            },
          ],
        })

        navMenuItems.push({
          title: 'Feedback',
          route: 'feedBack',
          icon: 'MessageCircleIcon',
        })

        return navMenuItems
      }

      // employee menu items
      if (
        this.$store.state.app.userInfo
        && (this.$store.state.app.userInfo.role == 'EMP'
          || this.$store.state.app.userInfo.role == 'MN')
      ) {
        if (
          this.$store.state.app.userInfo
          && this.$store.state.app.userInfo.is_resource_managing == 1
        ) {
          navMenuItems.push({
            title: 'Dashboards',
            route: 'dashboard',
            icon: 'HomeIcon',
            children: [
              {
                title: 'General',
                route: 'dashboard',
              },
              {
                title: 'QAssure',
                route: 'qassureDashBoard',
              },
            ],
          })
        } else {
          navMenuItems.push({
            title: 'Dashboards',
            route: 'dashboard',
            icon: 'HomeIcon',
          })
        }
        navMenuItems.push({
          title: 'Clock In/Out',
          route: 'clockInOut',
          icon: 'ClockIcon',
        })

        const timesheet = {
          title: 'Timesheet',
          route: 'timeSheet',
          icon: 'CalendarIcon',
          children: [
            {
              title: 'Timesheet Manage',
              route: 'timeSheetView',
            },
          ],
        }

        if (
          (this.$store.state.app.userInfo.manage_by
            && this.$store.state.app.userInfo.manage_by.length)
          || (this.$store.state.app.userInfo.users
            && this.$store.state.app.userInfo.users.length)
        ) {
          timesheet.children.push({
            title: 'Timesheet Approval',
            route: 'approval',
          })
        }

        if (this.userInfo && !this.userInfo.is_resource_managing) {
          timesheet.children.push({
            title: 'Timesheet Status',
            route: 'approvalStatus',
          })
        }
        navMenuItems.push(timesheet)
        navMenuItems.push({
          title: 'Projects',
          route: 'projects',
          icon: 'FileIcon',
          children: [
            {
              title: 'List',
              route: 'projects',
            },
          ],
        })
        if (
          this.$store.state.app.userInfo
          && this.$store.state.app.userInfo.is_resource_managing == 1
        ) {
          navMenuItems.push({
            title: 'Reports',
            route: 'reports',
            icon: 'FileTextIcon',
            children: [
              {
                title: 'All Reports',
                route: 'reports',
              },
              {
                title: 'Scheduled report',
                route: 'scheduledReport',
              },
            ],
          })
        } else {
          navMenuItems.push({
            title: 'Reports',
            route: 'reports',
            icon: 'FileTextIcon',
          })
        }
        navMenuItems.push({
          title: 'Board',
          route: 'board',
          icon: 'FileTextIcon',
        })
        if (
          (this.$store.state.app.userInfo.users
            && this.$store.state.app.userInfo.users.length)
          || (this.$store.state.app.userInfo.manage_by
            && this.$store.state.app.userInfo.manage_by.length)
        ) {
          navMenuItems.push({
            title: 'My Team',
            route: 'myTeam',
            icon: 'UsersIcon',
          })
        }
        if (
          this.$store.state.app.userInfo
          && this.$store.state.app.userInfo.is_resource_managing == 1
        ) {
          navMenuItems.push({
            title: 'Resource Planning',
            route: 'resourcePlanning',
            icon: 'UsersIcon',
            children: [
              {
                title: 'Planned',
                route: 'resourcePlanning',
              },
              {
                title: 'Actual',
                route: 'resourceActualPlanning',
              },
            ],
          })
        }

        navMenuItems.push({
          title: 'Help',
          route: 'userManual',
          icon: 'HelpCircleIcon',
          children: [
            {
              title: 'User Manual',
              route: 'userManual',
            },
          ],
        })

        navMenuItems.push({
          title: 'Feedback',
          route: 'feedBack',
          icon: 'MessageCircleIcon',
        })
      }

      return navMenuItems
    },

    /* master common Filter isActive */
    isActiveList() {
      return [
        { label: 'All', value: 'all_data' },
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ]
    },
  },
  watch: {
    projectId(nv) {
      const { params } = this.$route
      const query = {
        layout:
          this.$route.query
          && this.$route.query.layout
          && this.$route.query.layout == 'full'
            ? 'full'
            : 'vertical',
      }
      params.id3 = nv
      params.id4 = params.id == 'qassure' ? 'test-suite' : null

      params.id5 = null
      params.id6 = null
      params.id7 = null

      this.$router.push({
        params,
        query: {
          layout: this.currentLayout,
          pageName: this.$route.query.pageName,
        },
      })
      eventBus.$emit('callProjectDetailApi', true)
    },
  },
  mounted() {
    // Set coustom validation messages.
    localize('en', enValidation)

    // this.$echo.channel('employee_created_'+process.env.VUE_APP_CODE).listen('EmployeeCreated',(data) =>{
    //
    // });

    /* close alert we click in sidebar */
    eventBus.$on('closeSweetAlert', () => {
      this.$swal.close()
    })
  },
  methods: {
    encryptData(data) {
      const { Encrypt_key } = constantData // Key should be 16, 24, or 32 bytes long for AES
      const { Encrypt_iv } = constantData // Initialization Vector (same as above)
      const encrypted = CryptoJS.AES.encrypt(
        data,
        CryptoJS.enc.Utf8.parse(Encrypt_key),
        {
          iv: CryptoJS.enc.Utf8.parse(Encrypt_iv),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        },
      ).toString()
      return encrypted
    },
    setValidationMessage() {},
    loginResponse(token, user) {
      this.$store.commit('app/UPDATE_AUTH_TOKEN', token)
      token = CryptoJS.AES.encrypt(token, this.VUE_APP_SECRET_KEY).toString()
      localStorage.setItem('authToken', token)

      const userInfo = {
        id: user.id,
        name: user.name,
        phone: user.phone,
        email: user.email,
        role: user.role,
        // role: 'HR',
        profile_pic: user.picture,
        is_active: user.is_active,
        reporting_to: user.reporting_to,
        actual_rate: user.actual_rate,
        billable_rate: user.billable_rate,
        is_resource_managing: user.is_resource_managing,
        users: user.reporting && user.reporting.length ? user.reporting : [],
        manage_by:
          user.manage_by && user.manage_by.length ? user.manage_by : [],
      }
      // localStorage.setItem("user", JSON.stringify(userInfo));
      this.$store.commit('app/UPDATE_LOGIN_USER_INFO', userInfo)
      this.userDetails()
      this.userList()
      this.getTagsData()
      if (user.role == 'HR') {
        this.$router.push({ name: 'hr-dashboard' })
      } else {
        this.$router.push({ name: 'dashboard' })
      }
    },
    /* user List */
    async userDetails() {
      const response = await this.getHTTPPostResponse('login-user', {}, false)

      if (response && response.data && response.data.user) {
        const { user } = response.data
        const reporting = []
        const manage_by = []
        if (user.reporting && user.reporting.length) {
          user.reporting.forEach(element => {
            reporting.push({ id: element.id, name: element.full_name })
          })
        }
        if (user.manage_by && user.manage_by.length) {
          user.manage_by.forEach(element => {
            manage_by.push({ id: element.id, name: element.full_name })
          })
        }
        let userInfo = {
          id: user.id,
          name: user.name,
          last_name: user.last_name,
          full_name: `${user.name} ${user.last_name}`,
          // phone: user.phone,
          // email: user.email,
          role: user.role,
          // role: 'HR',
          profile_pic: user.picture,
          is_resource_managing: user.is_resource_managing,
          // is_active: user.is_active,
          // reporting_to: user.reporting_to,
          // actual_rate: user.actual_rate,
          // billable_rate: user.billable_rate,
          users: reporting,
          manage_by,
        }
        this.$store.commit('app/UPDATE_LOGIN_USER_INFO', userInfo)
        // Encrypt
        userInfo = CryptoJS.AES.encrypt(
          JSON.stringify(userInfo),
          this.VUE_APP_SECRET_KEY,
        ).toString()
        localStorage.setItem('user', userInfo)
      }
    },
    pad(num) {
      return `0${num}`.slice(-2)
    },

    secondsToTime(secs) {
      let minutes = Math.floor(secs / 60)
      secs %= 60
      const hours = Math.floor(minutes / 60)
      minutes %= 60
      if (hours < 99) {
        return `${`0${hours}`.slice(-2)}:${`0${minutes}`.slice(-2)}` // :${("0" + secs).slice(-2)
      }
      return `${hours}:${`0${minutes}`.slice(-2)}` // :${("0" + secs).slice(-2)
    },
    async getHTTPPostResponse(url, input = {}, success_message = false) {
      // Set headers if authToken exists
      if (this.$store.state.app.authToken) {
        this.$axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.app.authToken}`
        this.$axios.defaults.headers.common.redisKey = this.$store.state.app.redisKey
        this.$axios.defaults.headers.common.authToken = `Bearer ${this.$store.state.app.authToken}`
      }
      const encryptedData = this.encryptData(JSON.stringify(input))
      if (input && input._method) {
        input = { _method: input._method, payload: encryptedData }
      }else{
        input = { payload: encryptedData }
      }

      // Send the request
      try {
        const response = await this.$axios.post(url, input)
        const { data } = response

        if (success_message) {
          this.$bvToast.toast(data.message, {
            toaster: 'b-toaster-top-right',
            solid: true,
            title: 'Success',
            variant: 'success',
          })
        }
        return data || true
      } catch (e) {
        this.showError(e)
        const { data } = e.response
        if (data.status === 401) {
          this.$store.commit('app/UPDATE_AUTH_TOKEN', null)
          this.$store.commit('app/UPDATE_REDIS_KEY', null)
          this.$store.dispatch('app/UPDATE_LOGIN_USER_INFO', null)
          localStorage.removeItem('user')
          localStorage.removeItem('redisKey')
          localStorage.removeItem('authToken')
          localStorage.removeItem('hrTab')
          location.href = process.env.BASE_URL
        }
        return null
      }
    },
    async getHTTPPutResponse(url, input = {}, success_message = false) {
      if (this.$store.state.app.authToken) {
        this.$axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.app.authToken}`
        this.$axios.defaults.headers.common.redisKey = this.$store.state.app.redisKey
        this.$axios.defaults.headers.common.authToken = `Bearer ${this.$store.state.app.authToken}`
      }
      const encryptedData = this.encryptData(JSON.stringify(input))
      if (input && input._method) {
        input = { _method: input._method, payload: encryptedData }
      }else{
        input = { payload: encryptedData }
      }
      const data = await this.$axios
        .put(url, input)
        .then(response => {
          const { data } = response
          if (success_message) {
            this.$bvToast.toast(data.message, {
              toaster: 'b-toaster-top-right',
              solid: true,
              title: 'Success',
              variant: 'success',
            })
          }
          return data || true
        })
        .catch(e => {
          this.showError(e)
          return null
        })
      return data
    },
    async getHTTPGetResponse(url, input = {}, success_message = false) {
      if (this.$store.state.app.authToken) {
        this.$axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.app.authToken}`
        this.$axios.defaults.headers.common.redisKey = this.$store.state.app.redisKey
        this.$axios.defaults.headers.common.authToken = `Bearer ${this.$store.state.app.authToken}`
      }
      if (input && Object.keys(input).length > 0) {
        const encryptedData = this.encryptData(JSON.stringify(input))
        input = { payload: encryptedData }
      } else {
        input = {}
      }

      const data = await this.$axios
        .get(url, { params: input })
        .then(response => {
          const { data } = response
          if (success_message) {
            this.$bvToast.toast(data.message, {
              toaster: 'b-toaster-top-right',
              solid: true,
              title: 'Success',
              variant: 'success',
            })
          }
          return data || true
        })
        .catch(e => {
          this.showError(e)
          return null
        })
      return data
    },
    async getHTTPDeleteResponse(url, input = {}, success_message = false) {
      if (this.$store.state.app.authToken) {
        this.$axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.app.authToken}`
        this.$axios.defaults.headers.common.redisKey = this.$store.state.app.redisKey
        this.$axios.defaults.headers.common.authToken = `Bearer ${this.$store.state.app.authToken}`
      }
      const data = await this.$axios
        .delete(url, { params: input })
        .then(response => {
          const { data } = response
          if (success_message) {
            this.$bvToast.toast(data.message, {
              toaster: 'b-toaster-top-right',
              solid: true,
              title: 'Success',
              variant: 'success',
            })
          }
          return data || true
        })
        .catch(e => {
          this.showError(e)
          return null
        })
      return data
    },
    async logout() {
      const response = await this.getHTTPPostResponse('logout', {}, true)
      if (response && response.status == 200) {
        this.$store.commit('app/UPDATE_AUTH_TOKEN', null)
        this.$store.commit('app/UPDATE_REDIS_KEY', null)
        this.$store.dispatch('app/UPDATE_LOGIN_USER_INFO', null)
        localStorage.removeItem('user')
        localStorage.removeItem('redisKey')
        localStorage.removeItem('authToken')
        localStorage.removeItem('hrTab')
        location.href = process.env.BASE_URL
      } else {
        this.showError()
      }
    },

    /**  common function for filters */

    /*  groups */
    async getGroupData() {
      const groups = []
      const input = {
        sort_field: 'name',
        sort_order: 'asc',
        is_active: true,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/group',
        input,
        false,
      )
      if (response && response.data) {
        const data = response.data.groups

        data.forEach(element => {
          groups.push({
            value: element.id,
            label: element.name,
          })
        })
        this.$store.commit('app/UPDATE_GROUP_LIST', groups)
        return groups
      }
      this.showError()
    },
    async reportingtoUserList() {
      const input = {}
      const response = await this.getHTTPGetResponse(
        'admin/master/user/reporting-user-list',
        input,
        false,
      )

      if (response && response.data) {
        const { data } = response
        const users = []
        data.reporting_user_list.forEach(element => {
          users.push({
            value: element.id,
            label: `${element.name} ${element.last_name}`,
          })
        })

        this.$store.commit('app/UPDATE_REPORTING_USER_LIST', users)
        return users
      }
    },
    async getPrimarySkillData() {
      const input = {
        sort_field: 'name',
        sort_order: 'asc',
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/primary-skill',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        const userSkills = []
        data.primarySkills = [...new Set(data.primarySkills)]

        data.primary_skills.forEach(element => {
          userSkills.push({
            value: element.id,
            id: element.id,
            label: `${element.name}`,
            name: `${element.name}`,
          })
        })
        this.$store.commit('app/UPDATE_USER_SKILLS', userSkills)
        return userSkills
      }
    },
    async manageByUserList() {
      const input = {}
      const response = await this.getHTTPGetResponse(
        'admin/master/user/manage-user-list',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        const users = data.manage_user_list.map(element => ({
          value: element.id,
          label: `${element.name} ${element.last_name}`,
        }))

        if (this.userInfo && this.userInfo.role === 'EMP') {
          const currentUser = {
            value: this.userInfo.id,
            label: `${this.userInfo.name} ${this.userInfo.last_name}`,
          }
          if (!users.some(user => user.value === currentUser.value)) {
            users.push(currentUser)
            users.sort((a, b) => a.label.localeCompare(b.label))
          }
        }

        this.$store.commit('app/UPDATE_MANAGEBY_USER_LIST', users)
        return users
      }
    },

    async userList() {
      const input = {
        sort_field: 'name',
        sort_order: 'asc',
        is_active: 1,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/user',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        data.users.forEach(element => {
          element.value = element.id
          element.label = `${element.name} ${element.last_name}`
          element.name = `${element.name} ${element.last_name}`
        })
        this.$store.commit('app/UPDATE_USERS_LIST', data.users)
        return data.users
      }
    },

    /**
     * Get Projects data
     */
    async getProjectsData(id) {
      const input = {
        is_active: 1,
        type: 'projectDetail',
      }
      const url = id && id == 'generalBoard'
        ? 'project/active-sprint-project'
        : 'project/time-log'
      const response = await this.getHTTPPostResponse(url, input, false)
      if (response && response.data) {
        const { data } = response
        const productiveProject = []

        // Sort projects alphabetically by name
        data.projects.sort((x, y) => {
          const a = x.name.toUpperCase()
          const b = y.name.toUpperCase()
          return a == b ? 0 : a < b ? -1 : 1 // changed from 1 : -1 to -1 : 1 for ascending order
        })

        // Find General Board project
        const generalBoardProject = data.projects.find(
          project => project.code === 'ROJ',
        )

        if (generalBoardProject) {
          generalBoardProject.value = generalBoardProject.id
          generalBoardProject.label = 'General Board'
          generalBoardProject.name = 'General Board'
        }

        // Filter and process productive projects
        data.projects.forEach(element => {
          if (element.work_type === 'productive') {
            element.label = element.name
            element.value = element.id
            element.type = element.work_type
            productiveProject.push(element)
          }
        })

        // Move General Board project to the top
        if (generalBoardProject) {
          productiveProject.unshift(generalBoardProject)
        }

        this.$store.commit(
          'app/UPDATE_PRODUCTIVE_PROJECT_LIST',
          productiveProject,
        )
      }
    },

    /**
     * Get tags data
     */
    async getTagsData() {
      const input = {
        is_active: 1,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/tag',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.all_tags = data.count ? data.tags : []
        const tags = []
        data.tags.forEach(element => {
          tags.push({
            id: element.name,
            value: element.name,
            label: `${element.name}`,
            name: `${element.name}`,
          })
        })
        this.$store.commit('app/UPDATE_TAGS_LIST', tags)
        return tags
      }
    },

    showError(e) {
      if (e.response && e.response.status === 401) {
        this.logout()
      }
      if (!e.response || !e.response.data || !e.response.data.message) {
        return
      }
      const error = e.response.data.message
      this.$bvToast.toast(error, {
        toaster: 'b-toaster-top-right',
        solid: true,
        title: 'Unsuccess',
        variant: 'danger',
      })
    },
    randomVariant(index, length) {
      const variant = [
        'light-primary',
        'light-secondary',
        'light-success',
        'light-danger',
        'light-warning',
        'light-info',
      ]
      const random = Math.floor(Math.random() * (length - index) + index)
      return variant[random]
    },

    skillBackground() {
      const skillBackground = [
        'light-warning',
        'light-primary',
        'light-success',
        'light-danger',
        'light-info',
        'light-info',
      ]
      return skillBackground[
        Math.floor(Math.random() * skillBackground.length)
      ]
    },

    /** Convert data as parent child pair */
    convertToParentChildData(list, id = 'id', parent_id = 'parent_id') {
      const parentChildPair = []
      const idToObjectMap = {}

      list.forEach(item => {
        idToObjectMap[item[id]] = item

        item.children = []
      })
      list.forEach(item => {
        if (item[parent_id] !== null) {
          const parent = idToObjectMap[item[parent_id]]
          if (parent) {
            parent.children.push(item)
          }
        } else {
          parentChildPair.push(item)
        }
      })
      return parentChildPair
    },

    async getUserManualData() {
      const response = await this.getHTTPPostResponse(
        'admin/master/user-manual',
      )
      if (response && response.status) {
        const data = response.data.userManual
        this.$store.commit('app/UPDATE_USER_MANUAL_LIST', data)
        const parentChildData = this.convertToParentChildData(
          data,
          'code',
          'parent_code',
        )
        this.$store.commit(
          'app/UPDATE_ORIGINAL_USER_MANUAL_LIST',
          parentChildData,
        )
      }
    },

    async openFileInNewTab(url, openNewTab = true) {
      this.loader = true
      const input = {
        url,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/user-manual/get-temp-url',
        input,
      )

      if (response) {
        if (openNewTab) {
          const newTab = window.open(response.data.temp_url, '_blank')
          if (newTab) {
            newTab.focus()
          }
        } else {
          return response.data.temp_url
        }
      }
      this.loader = false
    },
    sortedUser(userList) {
      const users = [...userList] // Create a shallow copy to avoid modifying the original array
      users.sort((a, b) => {
        const nameA = `${a.name} ${a.last_name}`.toUpperCase()
        const nameB = `${b.name} ${b.last_name}`.toUpperCase()
        return nameA.localeCompare(nameB)
      })

      return users.map(element => ({
        value: element.id,
        label: `${element.full_name ? element.full_name : element.name}`,
      }))
    },
    async projectList() {
      let response
      let projects = []
      if (this.userInfo && this.userInfo.role === 'SA') {
        response = await this.getHTTPPostResponse(
          'project/log-project-list',
          {},
          false,
        )
      } else {
        response = await this.getHTTPPostResponse(
          'project/time-log',
          {},
          false,
        )
      }

      if (response && response.data) {
        const { data } = response
        projects = data.projects
      }
      return projects
    },

    async clientList() {
      const input = {
        sort_field: 'name',
        sort_order: 'asc',
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/client',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.$store.state.app.clientList = data.clients
      }
    },

    //  Timer Section Store variable value set
    clearTimerSectionState() {
      this.$store.state.app.dailyLogModelData.project_id = null
      this.$store.state.app.dailyLogModelData.task_name = null
      this.$store.state.app.dailyLogModelData.ticket_id = null
      this.$store.state.app.dailyLogModelData.tags = []
    },

    /* Reporting And Manage By Users List */
    async reportingAndManageByUserList() {
      const input = {
        sort_field: 'name',
        sort_order: 'asc',
      }
      const response = await this.getHTTPPostResponse(
        'timesheet-user-list',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        const { user } = data
        user.push(this.userInfo)

        user.forEach(element => {
          element.name = element.full_name
        })

        return user
      }
    },

    /* Reporting And Manage By Users List */
    async departmentList() {
      const input = {
        sort_field: 'name',
        sort_order: 'asc',
      }
      const response = await this.getHTTPPostResponse(
        'hrms-master/department-list',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        const { department } = data

        this.$store.commit('app/UPDATE_DEPARTMENT_LIST', department)
        return department
      }
    },

    /* Reporting And Manage By Users List */
    async divisionList() {
      const input = {
        sort_field: 'name',
        sort_order: 'asc',
      }
      const response = await this.getHTTPPostResponse(
        'hrms-master/division-list',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        const { division } = data
        this.$store.commit('app/UPDATE_DIVISION_LIST', division)
        return division
      }
    },

    /* Reporting And Manage By Users List */
    async employeeTypeList() {
      const input = {
        sort_field: 'name',
        sort_order: 'asc',
      }
      const response = await this.getHTTPPostResponse(
        'hrms-master/employee-type-list',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        const { employee_type_list } = data

        this.$store.commit('app/UPDATE_EMPLOYEE_TYPE_LIST', employee_type_list)
        return employee_type_list
      }
    },

    /*  common Function of weekly view */
    enumerateDaysBetweenDates(startDate, endDate) {
      const dates = []
      const currDate = moment(startDate).startOf('day')
      const lastDate = moment(endDate).startOf('day')
      // push start date
      dates.push(moment(startDate).format('YYYY-MM-DD'))

      // calculate dates
      while (currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(currDate.clone().format('YYYY-MM-DD'))
      }

      // push end date
      if (startDate != endDate) {
        dates.push(moment(endDate).format('YYYY-MM-DD'))
      }
      return dates
    },

    /* delete alert */
    async conformationAlert(isHtml = false, title, buttonText, htmlText) {
      let returnValue = null
      const obj = {
        title: title || 'Are you sure?',
        icon: 'info',
        html: isHtml
          ? htmlText
            || '<p>Once you delete you will not able to recover this record.</p>'
          : null,
        showCloseButton: false,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: buttonText || 'Delete',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel ml-1',
        },
        buttonsStyling: false,
      }
      await this.$swal(obj).then(value => (returnValue = value))
      return returnValue
    },
    /* check right time function */
    validateTime(startTime, endTime) {
      if (
        Date.parse(`01/01/2022 ${endTime}:00`)
        <= Date.parse(`01/01/2022 ${startTime}:00`)
      ) {
        this.$bvToast.toast('Please select right time.', {
          toaster: 'b-toaster-top-right',
          solid: true,
          title: 'End time is not valid',
          variant: 'danger',
        })
        return false
      }
      return true
    },
    /**
     * Resizing of Reason textarea
     */
    resizeTextarea() {
      this.$refs.textarea.style.height = 'auto'
      this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight}px`
    },

    /*  count total clock time */

    totalClockInOutMinute(data) {
      let total = 0
      if (data && data.length) {
        data.forEach(element => {
          if (!element.deleted_at) {
            if (element.out_time) {
              total += element.time_diff
            } else {
              const startDateTime = moment(
                `${element.in_date} ${element.in_time}`,
              ).format('YYYY-MM-DD HH:mm:ss')
              const now = moment(new Date())
              const duration = moment.duration(now.diff(startDateTime))
              const time = duration.asSeconds().toFixed(0)
              total += Number(time)
            }
          }
        })
      }
      return this.secondsToTime(total)
    },

    // get tags
    tagsArray(data) {
      const tags = data ? data.split(',') : []

      return tags
    },

    /* resource plan common function  */
    skillsFunction(data) {
      let skills = ''
      data.forEach((element, index) => {
        if (index !== 0) {
          skills = skills ? `${skills} , ${element.name}` : element.name
        }
      })
      return skills
    },

    getQuarterMonth(month) {
      let months
      switch (Number(month)) {
        case 1:
          months = ['01', '02', '03']
          break
        case 2:
          months = ['04', '05', '06']
          break
        case 3:
          months = ['07', '08', '09']
          break
        default:
          months = ['10', '11', '12']
      }
      return months
    },

    generateMonthLabels(months) {
      const labels = [
        { key: 'name', label: 'Name' },
        { key: 'january', label: moment(`${months[0]}`, 'MM').format('MMMM') },
        { key: 'feb', label: moment(`${months[1]}`, 'MM').format('MMMM') },
        { key: 'march', label: moment(`${months[2]}`, 'MM').format('MMMM') },
      ]

      return labels
    },

    filterUserOptionsFunction(users, userSearch) {
      const filteredUsers = []

      users.forEach(element => {
        if (
          this.userInfo.role === 'SA'
          || element.manage_by === this.userInfo.id
        ) {
          filteredUsers.push({
            value: element.id,
            label: `${element.full_name}`,
          })
        }
      })

      if (userSearch) {
        return users.filter(element => element.full_name.toLowerCase().includes(userSearch.toLowerCase()))
      }

      return filteredUsers
    },

    /* resource common Function end */

    /* project common Function start */

    redirectToOtherTab(params, query) {
      this.$router.push({
        name: 'projectTask',
        params,
        query,
      }).catch(err => {
        // Ignore the NavigationDuplicated error
        if (err.name !== 'NavigationDuplicated') {
          console.log(err)
        }
      })
    },

    DDMMYYYYHHMMSSformat(value) {
      return value
        ? moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm')
        : null
    },

    /* open user profile */
    openUserProfile(id) {
      if (!id) return;
      (this.$store.state.app.viewUserProfileId = id),
      (this.$store.state.app.isUserProfileOpen = true)
    },

    /* member watch list & member summary */
    getFormattedTime(clock, type) {
      if (!clock?.length) {
        return 'N/A'
      }

      // Sort clock array by in_time
      // clock.sort((a, b) => new Date(b.in_time) - new Date(a.in_time))

      let time

      switch (type) {
        case 'lastIn':
          time = clock[clock.length - 1]?.in_time
          break

        case 'firstIn':
          time = clock[0]?.in_time
          break

        case 'lastOut':
          const lastIndex = clock.length - 1
          time = clock[lastIndex]?.out_time || clock[lastIndex - 1]?.out_time

          break

        default:
          return 'N/A'
      }

      return this.$options.filters.formatTimeHHMM(time) || 'N/A'
    },

    /* query */
    routeQueryUpdate(queryData) {
      this.$router.replace({ query: queryData }).catch(err => {
        // Ignore the NavigationDuplicated error
        if (err.name !== 'NavigationDuplicated') {
          console.log(err)
        }
      })
    },

    // Helper function to convert file to Base64
    async convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
  },
})
